import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages";
import { About } from "./pages/about";
import  { Contact } from "./pages/contact";
import  {Internship}  from "./pages/internship";
import { Solution } from "./pages/solution";
import { Blog } from "./pages/blogs";
import { PrivacyPolicy } from "./pages/privacy-policy";
import Cookie from "./pages/cookies";

function App() {
  return (
    <Router>
      <Cookie></Cookie>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} exact />
        <Route path="/blogs" component={Blog} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/careers" component={Internship} exact />
        <Route path="/solution" component={Solution} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
      </Switch>
    </Router>
  );
}

export default App;
