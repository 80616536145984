import React, { useEffect, useState } from "react";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Footer from "../components/footer";
import Signin from "../components/contact";
import StickyContact from "../components/sticky-contact";
import { MapsContainer } from "../components/contact/SigninElements";

export const Contact = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Connect with Us: Your Gateway to Expertise';
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Signin />
      <Footer />
      <StickyContact />
    </>
  );
};