import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
    //background: #a9bdc3;
    background: ${({scrollNav}) => (scrollNav ? '#a9bdc3' : 'transparent')};
    height: 80px;
    margin-top: -80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
    position: sticky;
    top: 0;
    z-index: 10;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    margin: 0 50px;

    @media screen and (max-width: 768px){
        margin: 0 20px;
    }

`
export const NavLogo = styled(LinkR)`
    justify-self: center;
    cursor: pointer;
    display: flex;
    align-items: center;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 940px) {
        display: block;
        //position: absolute;
        //top: 0;
        //right: 0;
        margin-top: auto;
        margin-bottom: auto;
        //transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #2A2E30;
    }

`

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-left: 150px;
    height: 80px;

    @media screen and (max-width: 940px) {
        display: none;
    }

    @media screen and (max-width: 1290px) {
        margin-left: 80px;
    }

    @media screen and (max-width: 1190px) {
        margin-left: 0;
    }

`

export const NavItem = styled.li`
    height: 25px;
    margin: 0 2rem;
    color: #2A2E30;
    display: flex;
    font-weight: bold;
    letter-spacing: 0.5px;
    align-self: center;
    font-size: 17px;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        border-radius: 10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #E0703B;
        transform: scaleX(0);
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
    }

    &.active {
        border-bottom: 3px solid #E0703B;
    }

    &:hover::after {
        transform: scaleX(1);
        transform-origin: center;
    }

    @media screen and (max-width: 1200px) {
        margin: 0 1rem;
    }

    @media screen and (max-width: 1085px) {
        margin: 0 .8rem;
    }

    @media screen and (max-width: 960px) {
        &::after, &.active, &:hover::after {
            display: none !important;
        }
    }
`

export const NavButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 940px) {
        display: none;
    }
`

export const NavLinks = styled(LinkR)`
    color: #fff;
    display: flex;
    align-self: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.active {
        border-bottom: 3px solid #E0703B;
    }

    &:hover {
        color: #000;
    }

`
export const NavLink = styled(LinkR)`
    color: #2A2E30;
    display: flex;
    align-self: center;
    justify-content: center;
    text-decoration: none;
    height: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: #E0703B;
    }

`

export const NavDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 120%;
  border-radius: 10px;
  color: #fff;
  background-color: #ccc;
  padding: 5px 20px;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
`