import styled from "styled-components";

export const VisionMission = styled.div `
    display: flex;
    background: #D4EDF4;
    padding: 20px 80px;

    @media  screen and (max-width: 958px) {
        padding: 50px;
    }

    @media  screen and (max-width: 591px) {
        padding: 10px;
    }
`

export const VMWrapper = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 100px;

    @media screen and (max-width: 1281px) {
        flex-direction: column;
        margin: 0 10px;
    }

    @media screen and (max-width: 431px) {
        flex-direction: column;
        margin: 0 10px;
    }

    
`

export const VMContent = styled.div `
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 20px;

    @media screen and (max-width: 1281px) {
        align-items: center;
        flex-direction: row;
        margin: 30px 0;
    }

    @media screen and (max-width: 958px) {
        flex-direction: column;
    }
`
export const VMText = styled.div `
    display: flex;
    flex-direction: row;
    color: #000;
    width: 80%;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 431px) {
        flex-direction: column;
        width: 90%;
        margin-top: 50px;
        gap: 50px;
    }
`