import React from "react";
import "../../App.css";

const PP = () => {
    return (
        <div style={{ paddingTop: "120px", paddingBottom: "50px", paddingLeft: "50px", paddingRight: "50px", background: "rgb(212, 237, 244)"}} className="pp">
        <h1>Privacy Policy</h1>
        <p><strong>Last updated July 7, 2024</strong></p>
        <p>This privacy notice for XpertLyte Technologies Pvt Ltd (<strong>"Company"</strong>, "we," <strong>"us"</strong>, or <strong>"our"</strong>), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services <strong>("Services")</strong>, such as when you:</p>
        <ul>
            <li>Visit our website at <a href="https://xpertlyte.com">https://xpertlyte.com</a>, or any website of ours that links to this privacy notice</li>
            <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <p><strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:hello@xpertlyte.com">hello@xpertlyte.com</a>.</p>
        
        <div class="summary" style={{ paddingTop: "50px" }}>
            <h2>SUMMARY OF KEY POINTS</h2>
            <p>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>
            <ul>
                <li><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with XpertLyte Technologies Pvt Ltd and the Services, the choices you make, and the products and features you use. Learn more about the personal information you disclose to us.</li>
                <li><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</li>
                <li><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</li>
                <li><strong>How do we keep your information safe?</strong> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</li>
                <li><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</li>
                <li><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by emailing us at hello@xpertlyte.com. We will consider and act upon any request under applicable data protection laws. Want to learn more about what XpertLyte Technologies Pvt Ltd does with any information we collect? Review the privacy notice in full.</li>
            </ul>
        </div>

        <div class="toc" style={{ paddingTop: "50px" }}>
            <h2>TABLE OF CONTENTS</h2>
            <ol>
                <li style={{paddingTop: "10px"}}><a href="#1">WHAT INFORMATION DO WE COLLECT?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#2">HOW DO WE PROCESS YOUR INFORMATION?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#3">WHAT LEGAL BASIS DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#4">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#5">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#6">IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#7">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#8">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#9">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#10">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#11">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
                <li style={{paddingTop: "10px"}}><a href="#12">DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#13">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
                <li style={{paddingTop: "10px"}}><a href="#14">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a></li>
            </ol>
        </div>

        <div id="1" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
            <p><strong>In Short:</strong> We collect personal information that you provide to us.</p>
            <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. When you share your personal information or the personal information of any other person, you confirm that you have the legal right and authority to share such information.</p>
            <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
            <ul>
                <li>names</li>
                <li>email addresses</li>
                <li>job titles</li>
                <li>contact information</li>
            </ul>
            <p><strong>Sensitive Information.</strong> We do not process sensitive information. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
            <p><strong>Information automatically collected</strong></p>
            <p><strong>In Short: </strong>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services and for our internal analytics and reporting purposes.

Like many businesses, we also collect information through cookies and similar technologies. The information we collect includes:
</p>
<ul>
    <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages, and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).</li>
    <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
    <li>Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.</li>
</ul>
        </div>

        <div id="2" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
            <p><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the applicable law. We may also process your information for other purposes with your consent.</p>
            <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
            <ul>
                <li>to provide, maintain and enhance your experience with the services provided by us</li>
                <li>to take up and investigate any complaints/claims/ disputes</li>
                <li>to respond to your queries or feedback submitted by you</li>
                <li>to link or combine with information XpertLyte receives from others to understand your needs and provide better service</li>
                <li>to carry out any other purpose for which the information was collected</li>
                <li>to share the data such as backtesting, user action, and processed data for internal and external services with third parties without your consent</li>
            </ul>
        </div>

        <div id="3" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>3. WHAT LEGAL BASIS DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</h2>
            <p><strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
        </div>

        <div id="4" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
            <p><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</p>
            <p>We may need to share your personal information in the following situations:</p>
            <ul>
                <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                <li>As required under applicable laws.</li>
            </ul>
        </div>

        <div id="5" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
            <p><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</p>
            <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
        </div>

        <div id="6" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
            <p><strong>In Short:</strong> We may transfer, store, and process your information in countries other than your own.</p>
            <p>Our servers are located in India. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above). We will take all necessary measures to protect your personal information per this privacy notice and applicable law. In case of any transfer of your information, XpertLyte will use its best endeavors to put in place appropriate controls and safeguards to ensure that such information is kept accurate, adequately protected, and processed only for specified and reasonable purposes in a manner that is fair, transparent and has a lawful basis, and is stored for no longer than is necessary.</p>
        </div>

        <div id="7" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
            <p><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
        </div>

        <div id="8" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
            <p><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</p>
            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment. Your information in the possession and control of XpertLyte is stored under applicable laws. Storage and transfer of your information is carried out in compliance with all applicable laws, including without limitation, the Information Technology Act, 2000 and the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011.</p>
        </div>

        <div id="9" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
            <p><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under the age of 18, please contact us at <a href="mailto:hello@xpertlyte.com">hello@xpertlyte.com</a>.</p>
        </div>

        <div id="10" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
            <p><strong>In Short:</strong> In some regions, such as, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
In some regions (like), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
</p>
            <p>We will consider and act upon any request under applicable data protection laws.</p>
            <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.</p>
            <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
            <p><strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
            <p><strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you – for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
            <p><strong>Cookies and similar technologies: </strong>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services.</p>
            <p>If you have questions or comments about your privacy rights, you may email us at hello@xpertlyte.com.</p>
        </div>

        <div id="11" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
        </div>

        <div id="12" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
            <p><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
        </div>

        <div id="13" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
            <p>If you have questions or comments about this notice, you may email us at <a href="mailto:hello@xpertlyte.com">hello@xpertlyte.com</a> or by post at:</p>
            <div class="contact-info">
                <p>XpertLyte Technologies Pvt Ltd</p>
                <p>A-1001, Akshar Business Park Sec-25 Vashi, </p>
                <p>Navi Mumbai, 400703</p>
                <p>India</p>
            </div>
            <p>In case you have any queries or any discrepancies/grievances with respect to your information, please reach out to the XpertLyte Data Protection and Grievance team at:</p>
            <div class="contact-info">
                <p><strong>Email: </strong> privacy@xpertlyte.com</p>
                <p><strong>Address: </strong></p>
                <p>XpertLyte Technologies Pvt Ltd</p>
                <p>A-1001, Akshar Business Park Sec-25 Vashi, </p>
                <p>Navi Mumbai, 400703</p>
                <p>India</p>
            </div>
        </div>

        <div id="14" class="section-title" style={{ paddingTop: "100px" }}>
            <h2>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: hello@xpertlyte.com.</p>
        </div>
    </div>
    );
};

export default PP;