import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Title, WrapAbout, Who, What, P, Navigation, Button } from "./InfoElements";
import WhatImage from "../../images/who-gif.gif";
import backgroundVideo from "../../images/About Us.mp4";

export const InfoSection = () => {
  const [activeSection, setActiveSection] = useState("who");

  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <video autoPlay loop muted playsInline className="bg-video">
            <source src={backgroundVideo} type="video/mp4" />
          </video>
          <WrapAbout>
          <div>
            <Navigation>
              <Button onClick={() => setActiveSection("who")} active={activeSection === "who"}>Who</Button>
              <Button onClick={() => setActiveSection("what")} active={activeSection === "what"}>What</Button>
            </Navigation>
            {activeSection === "who" ? (
              <Who>
                <Title>Who We Are?</Title>
                <P>
                  XpertLyte is a dynamic team of tech enthusiasts, developers, designers, and strategists dedicated to delivering cutting-edge solutions tailored to meet the unique needs of our clients. With years of collective experience and a commitment to excellence, we pride ourselves on our ability to turn ideas into reality and challenges into opportunities.
                </P>
              </Who>
            ) : (
              <What>
                <Title>What We Do?</Title>
                <P>
                  From web development and software development to app development, testing services, cybersecurity, and tech consulting, we offer a comprehensive suite of services designed to propel businesses forward in today’s rapidly evolving digital landscape. Whether you’re a startup looking to establish your online presence or an established enterprise seeking to optimize your operations, we have the expertise and creativity to bring your vision to life.
                </P>
              </What>
            )}
            </div>
            <img src={WhatImage} style={{ width: "400px", height: "auto" }} className="ab-img" />
          </WrapAbout>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
