import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import "../../App.css";
import '@fortawesome/fontawesome-free/css/all.min.css'

const FeaturedContainer = styled.div`
  display: flex;
  margin: 5rem 1rem;

  @media screen and (max-width: 1240px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 3rem 1rem;
  }
`;

const Post = styled.div`
  width: 70%;
  margin: 0 40px;

  @media screen and (max-width: 1240px) {
    width: 90%;
    margin: 0 2rem;
  }

  @media screen and (max-width: 431px) {
    margin: 0 1rem;
  }
`;

const Sidebar = styled.div`
  width: 30%;

  @media screen and (max-width: 1240px) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const PostContent = styled.div`
  display: ${({ expanded }) => (expanded? 'block' : 'none')};
`;

const CategoryFilter = styled.div`
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #020202;
  margin-right: 50px;

  @media screen and (max-width: 1240px) {
    margin-top: 0;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
`;

const CategoryCheckbox = styled.input`
  margin-right: 10px;
`;

const CategoryLabel = styled.label`
  display: block;
  margin-bottom: 10px;

  @media screen and (max-width: 1240px) {
    align-items: center;
    margin-bottom: 0;
    margin-top: 5px;
  }
`;

  const SearchBar = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  input[type="search"] {
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
  }
`;

const FeaturedPosts = ({ posts, images }) => {
  const [expandedPost, setExpandedPost] = useState(null);
  const [likes, setLikes] = useState({});
  const [comments, setComments] = useState({});
  const [shares, setShares] = useState({});
  const [commentInput, setCommentInput] = useState({});
  const [limit, setLimit] = useState(2);
  const [category, setCategory] = useState('latest');
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const storedLikes = localStorage.getItem('likes');
    if (storedLikes) {
      setLikes(JSON.parse(storedLikes));
    }
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredPosts = posts.filter((post) => {
      const title = post.title.toLowerCase();
      const excerpt = post.excerpt.toLowerCase();
      const content = post.content.toLowerCase();
      return title.includes(query) || excerpt.includes(query) || content.includes(query);
    });
    setFilteredPosts(filteredPosts);
  };

  const toggleReadMore = (index) => {
    setExpandedPost(expandedPost === index? null : index);
  };

  const handleSeeAll = () => {
    setLimit(posts.length);
  };

  const handleLike = (index) => {
    setLikes((prevLikes) => {
      const newLikes = {...prevLikes };
      if (newLikes[index]) {
        delete newLikes[index];
      } else {
        newLikes[index] = (newLikes[index] || 0) + 1;
      }
      localStorage.setItem('likes', JSON.stringify(newLikes));
      return newLikes;
    });
  };

  const handleComment = (index) => {
    const post = posts[index];
    const subject = `Comment on: ${post.title}`;
    const commentBox = document.createElement('div');
    commentBox.style.position = 'fixed';
    commentBox.style.top = '50%';
    commentBox.style.left = '50%';
    commentBox.style.transform = 'translate(-50%, -50%)';
    commentBox.style.background = 'white';
    commentBox.style.padding = '20px';
    commentBox.style.border = '1px solid #ccc';
    commentBox.style.borderRadius = '5px';
    commentBox.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.2)';
    commentBox.style.display = 'flex';
    commentBox.style.flexDirection = 'column';
    commentBox.style.alignItems = 'center';
    commentBox.style.width = '70vw';
    commentBox.style.margin = '20px 0';
  
    const title = document.createElement('h5');
    title.textContent = 'Leave Your Comment To Us!';
    title.style.marginBottom = '10px';
  
    const commentInput = document.createElement('textarea');
    commentInput.style.width = '100%';
    commentInput.style.height = '100px';
    commentInput.style.padding = '10px';
    commentInput.style.border = '1px solid #ccc';
    commentInput.style.borderRadius = '5px';
    commentInput.placeholder = 'Enter your message to us';
  
    const sendButton = document.createElement('button');
    sendButton.textContent = 'Send';
    sendButton.style.background = '#e07038';
    sendButton.style.color = 'white';
    sendButton.style.padding = '4px 20px';
    sendButton.style.border = 'none';
    sendButton.style.borderRadius = '5px';
    sendButton.style.cursor = 'pointer';
    sendButton.style.marginTop = '20px';
  
    commentBox.appendChild(title);
    commentBox.appendChild(commentInput);
    commentBox.appendChild(sendButton);
  
    const backdrop = document.createElement('div');
    backdrop.style.position = 'fixed';
    backdrop.style.top = '0';
    backdrop.style.left = '0';
    backdrop.style.width = '100%';
    backdrop.style.height = '100%';
    backdrop.style.background = 'rgba(0, 0, 0, 0.5)';
    backdrop.style.filter = 'blur(5px)';
  
    document.body.appendChild(backdrop);
    document.body.appendChild(commentBox);

    backdrop.addEventListener('click', () => {
      commentBox.remove();
      backdrop.remove();
    });
  
    sendButton.addEventListener('click', () => {
      const commentText = commentInput.value;
      const emailLink = `mailto:hello@xpertlyte.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(commentText)}`;
      window.open(emailLink, '_blank');
      commentBox.remove();
      backdrop.remove();
    });
  };

  const handleShare = (index) => {
    const postUrl = `https://xpertlyte.com/blogs`;
    const shareOptions = {
      title: posts[index].title,
      text: posts[index].excerpt,
      url: postUrl,
    };

    if (navigator.share) {
      navigator.share(shareOptions)
       .then(() => console.log('Shared successfully'))
       .catch((error) => console.error('Error sharing:', error));
    } else {
      const copyLinkButton = document.createElement('button');
      copyLinkButton.textContent = 'Copy link';
      copyLinkButton.addEventListener('click', () => {
        navigator.clipboard.writeText(postUrl);
        alert('Link copied to clipboard!');
      });

      const shareContainer = document.createElement('div');
      shareContainer.appendChild(copyLinkButton);

      const emailButton = document.createElement('button');
      emailButton.textContent = 'Email';
      emailButton.addEventListener('click', () => {
        const emailLink = `mailto:?subject=${shareOptions.title}&body=${shareOptions.text} ${shareOptions.url}`;
        window.open(emailLink, '_blank');
      });
      shareContainer.appendChild(emailButton);

      const shareButton = document.querySelector(`[data-index="${index}"]`);
      shareButton.parentNode.appendChild(shareContainer);
    }
  };

  const handleCategoryChange = (category) => {
    setCategory(category);
    const filteredPosts = posts.filter((post) => post.category === category);
    setFilteredPosts(filteredPosts);
  };

  return (
    <FeaturedContainer>
      <Post>
      <div style={{
        display:'flex',
        justifyContent:'space-between',
      }} className='search-sub'>
        <h1 style={{marginBottom: "20px", color: "#E0703B", fontWeight: "bold"}}>Featured Blog Posts</h1>
        <SearchBar>
          <input
            type="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search posts..."
          />
        </SearchBar>
      </div>
        {filteredPosts.slice(0, limit).map((post, index) => (
          <div key={index}>
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', margin: "20px 0",gap:"20px"}} className='head-post'>
            <h3 style={{fontWeight: "bold"}}>{post.title}</h3>
            <b style={{fontSize: '0.8em', color: '#666'}}>Published on: {new Date(post.date).toLocaleString()}</b>
            <span style={{fontSize: '0.8em', color: '#020202', fontWeight: 'bold'}}>Contributor: {post.contributor}</span>
          </div>
            <p style={{display: 'none'}}>{post.category}</p>
            <p>{post.excerpt}</p>
            <PostContent expanded={expandedPost === index}>
              <img src={post.image} alt={post.title} style={{ width: 'auto', height: '400px', margin: '50px 0' }} className='blog-image'/><br></br>
              {post.content.split('\n').map((paragraph, index) => (
               <div key={index}>
                {paragraph.startsWith('# ')? (
                  <h4 style={{ fontWeight: 'bold', marginBottom: '20px' }}>{paragraph.replace('# ', '')}</h4>
                ) : paragraph.startsWith('## ')? (
                  <h5 style={{ fontWeight: 'bold', marginBottom: '20px'  }}>{paragraph.replace('## ', '')}</h5>
                ) : paragraph.startsWith('### ')? (
                  <h6 style={{ fontWeight: 'bold', marginBottom: '20px'  }}>{paragraph.replace('### ', '')}</h6>
                ) : (
                  <p>{paragraph}</p>
                )}
               </div>
              ))}
            </PostContent>
            <button onClick={() => toggleReadMore(index)} className='r-btn'>
              {expandedPost === index? '...Read Less' : 'Read More...'}
            </button>
            <div>
            {/* <button onClick={() => handleLike(index)} className='l'>
              {likes[index] ? (
                <i className="fa-solid fa-thumbs-up"></i>
                ) : (
                <i className="fa-regular fa-thumbs-up"></i>
              )}
              {likes[index] || 0}
            </button>
              <button onClick={() => handleComment(index)} className='c'>
              <i className="fa-regular fa-comment"></i>
              </button> */}
              <button onClick={() => handleShare(index)} className='s'>
              <i className="fa-solid fa-share-nodes"></i>
              </button>
            </div>
          </div>
        ))}
      {limit < filteredPosts.length && (
        <button onClick={handleSeeAll} className='s-btn'>
          See All ({posts.length})
        </button>
      )}
      </Post>
      <Sidebar>
      <div>
        <h3 className='blog-sub-h3'>Subscribe for updates</h3>
        <form style={{display: 'flex', gap: '0.5rem', marginTop: '2rem'}} className='subscribe-blog'>
          <input type="email" placeholder="Enter your email" className='blog-sub' /><br></br>
          <button
            type="submit"
            className="sub-btn"
            onClick={(e) => {
              e.preventDefault();
              const email = document.querySelector('.blog-sub').value;
              if (email) {
                alert('Thank you for subscribing! We\'ll notify you about our next post.');
                console.log(`Subscribed: ${email}`);
              } else {
                alert('Please enter a valid email address.');
              }
            }}
          >
            Subscribe
          </button>
        </form>
      </div>
      <CategoryFilter>
      <h4>Filter by category:</h4><br></br>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'technology'}
          onChange={() => handleCategoryChange('technology')}
        />
        Technology
      </CategoryLabel>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'science'}
          onChange={() => handleCategoryChange('science')}
        />
        Science
      </CategoryLabel>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'startups'}
          onChange={() => handleCategoryChange('startups')}
        />
        Startups
      </CategoryLabel>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'education'}
          onChange={() => handleCategoryChange('education')}
        />
        Education
      </CategoryLabel>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'ai'}
          onChange={() => handleCategoryChange('ai')}
        />
        Artificial Intelligence
      </CategoryLabel>
      <CategoryLabel>
        <CategoryCheckbox
          type="checkbox"
          checked={category === 'market-trend'}
          onChange={() => handleCategoryChange('market-trend')}
        />
        Market Trend
      </CategoryLabel>
    </CategoryFilter>
      </Sidebar>
    </FeaturedContainer>
  );
};

export default FeaturedPosts;