import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import Logo from "../../images/logo.png";
import { useHistory } from 'react-router-dom';
import { ButtonR } from "../ButtonElements";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavLink,
  NavButton,
  NavDropdown
} from "./NavbarElements";
import "./../../App.css";

export const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  const NavLinks = ({ to, ...props }) => {
    const history = useHistory();
  
    const handleClick = (e) => {
      e.preventDefault();
      history.push(`/${to}`);
    };

    return <a {...props} onClick={handleClick} />;
  };
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);      
  }, [])

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/">
            <img src={Logo} style={{width:'auto', height:'70px'}} className="logo"/>
          </NavLogo>
          <NavMenu>
            <NavItem>
              <NavLinks to="#">Home</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="about">About</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="solution">Solution</NavLinks>
            </NavItem>
            {/* <NavItem
              onMouseEnter={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
              onClick={() => setDropdown(!dropdown)}>
                Solution
                {dropdown && (
                <NavDropdown>
                  <NavLink to="solution">Healthcare</NavLink>
                </NavDropdown>
                )}
            </NavItem> */}
            <NavItem>
              <NavLinks to="blogs">Blogs</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="careers">Careers</NavLinks>
            </NavItem>
          </NavMenu>
          <NavButton>
            <ButtonR
                to="/contact"
                onMouseEnter={onHover}
                onMouseLeave={onHover}
                primary="true"
                dark="true"
              >
                Contact
            </ButtonR>
          </NavButton>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
        </NavbarContainer>
      </Nav>
    </>
  );
};