import React from "react";
import styled from "styled-components";
import blogImage from "../../images/blog-bg.jpg"

const BlogContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: left;
   height: 750px;
   padding: 0 50px;
   gap: 1rem;
   background-image: linear-gradient(to right, rgb(212, 237, 244,1), rgb(212, 237, 244,0.5)), url(${blogImage});
   background-size: cover;
   background-position: right;

   @media screen and (max-width: 768px) {
    padding: 0 3rem;
   }

   @media screen and (max-width: 431px) {
    padding: 0 2rem;
   }
`
const H3 = styled.h3`
   font-size: 70px;
   color: #E0703B;
   font-weight: bold;

   @media screen and (max-width: 1100px) {
    font-size: 50px;
   }

   @media screen and (max-width: 431px) {
    font-size: 30px;
   }
`
const H5 = styled.h5`
   font-size: 30px;
   font-weight: bold;

   @media screen and (max-width: 1100px) {
    font-size: 25px;
   }

   @media screen and (max-width: 431px) {
    font-size: 18px;
   }
`

const BlogCard = () => {
    return (
        <BlogContainer>
            <H5>Unlock Transformative Success: One Breakthrough at a Time</H5>
            <H3>Revealing the Routes to Groundbreaking Innovations</H3>
            <H5>Uncover the Mindset Driving Revolutionary Innovations</H5>
        </BlogContainer>
    )
}

export default BlogCard;