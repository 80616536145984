import styled from "styled-components";

export const ServicesContainer = styled.div`
    min-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 50px 0;

    @media screen and (max-width: 768px) {
        min-height: 1100px;
    }

    @media screen and (max-width: 480px) {
        min-height: 1300px;
    }

`

export const ServicesWrapper = styled.div`
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    grid-auto-columns: 1fr;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
    padding: 20px 50px;

    @media screen and (max-width: 1240px) {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 20px 50px;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        padding: 20px 20px !important;
    }

`
export const ServicesImageLeft = styled.div`
    display: flex;
    margin-left: 80px;
`
export const ServicesCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 350px;
    background-color: #d4edf4;
    text-align: center;
    padding: 30px 10px;
    border-radius: 30px;
    transition: transform 0.3s ease-in-out;

    :hover {
        transform: scale(1.05);
        box-shadow: 0 0 26px rgba(9, 9, 9, 0.3);
        z-index: 1;
    }

    @media screen and (max-width: 768px) {
        padding: 20px 20px;
    }

`

export const ServicesIcon = styled.img`
    height: 90px;
    width: auto;
    margin-bottom: 20px;
    
`

export const ServicesH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: bold;
    color: #020202;
    margin-bottom: 20px;

    @media screen and (max-width: 431px) {
        font-size: 1.5rem;
        margin: 10px 10px;
    }

`

export const ServicesH2 = styled.h2`
    font-size: 1.2rem;
    margin: 20px 0;
    font-weight: 900;
    
`

export const ServicesP = styled.p`
    font-size: 1rem;
    margin-bottom: 40px;

    @media screen and (max-width: 431px) {
        margin: 10px 10px;
    }
`
