import React, {useState} from "react";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, ServicesImageLeft} from "./ServicesElements";
import Image from "../../images/image.png";
import Icon1 from "../../images/web-d.png";
import HoverIcon1 from "../../images/web-d-hover.png";
import Icon2 from "../../images/soft-d.png";
import HoverIcon2 from "../../images/soft-d-hover.png";
import Icon3 from "../../images/app-d.png";
import HoverIcon3 from "../../images/app-d-hover.png";
import Icon4 from "../../images/c-sec.png";
import HoverIcon4 from "../../images/c-sec-hover.png";
import Icon5 from "../../images/test.png";
import HoverIcon5 from "../../images/test-hover.png";
import Icon6 from "../../images/tech.png";
import HoverIcon6 from "../../images/tech-hover.png";
import "../../App.css";

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const handleCardHover = (card) => {
    setHoveredCard(card);
  };

  const handleCardLeave = () => {
    setHoveredCard(null);
  };

  return (
    <ServicesContainer id="services">
      <ServicesH1>Comprehensive IT Services</ServicesH1>
      <ServicesP>From ideation to execution, XpertLyte offers a full spectrum of IT services designed to empower your business in the evolving digital landscape.</ServicesP>
      <ServicesWrapper>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card1")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card1" ? HoverIcon1 : Icon1} />
          <ServicesH2>Web Development</ServicesH2>
          <ServicesP>
          Crafting state-of-the-art websites that combine form and function to provide an unparalleled user experience.
          </ServicesP>
        </ServicesCard>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card2")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card2" ? HoverIcon2 : Icon2} />
          <ServicesH2>Software Development</ServicesH2>
          <ServicesP>
          Bespoke software solutions engineered to streamline processes, enhance productivity, and drive innovation.
          </ServicesP>
        </ServicesCard>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card3")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card3" ? HoverIcon3 : Icon3} />
          <ServicesH2>App Development</ServicesH2>
          <ServicesP>
          Mobile applications that connect with customers, delivering high performance and consistent engagement across devices.
          </ServicesP>
        </ServicesCard>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card4")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card4" ? HoverIcon4 : Icon4} />
          <ServicesH2>Cyber Security</ServicesH2>
          <ServicesP>
          Robust security strategies protecting your assets from cyber threats, ensuring business continuity and peace of mind.
          </ServicesP>
        </ServicesCard>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card5")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card5" ? HoverIcon5 : Icon5} />
          <ServicesH2>Testing Services</ServicesH2>
          <ServicesP>
          Comprehensive testing regimes for software and security that certify quality and fortify against vulnerabilities.
          </ServicesP>
        </ServicesCard>
        <ServicesCard 
          onMouseEnter={() => handleCardHover("card6")} 
          onMouseLeave={handleCardLeave}
        >
          <ServicesIcon src={hoveredCard === "card6" ? HoverIcon6 : Icon6} />
          <ServicesH2>Tech Consulting</ServicesH2>
          <ServicesP>
          Insightful technology consulting that aligns your IT strategies with business objectives for a competitive edge.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;