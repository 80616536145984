import styled from 'styled-components';

export const ClientTestimonial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #888;
  padding: 50px;
  text-align: center;
  height: 20rem;

  @media screen and (max-width: 1183px) {
    padding: 40px;
  }

  @media screen and (max-width: 825px) {
    padding: 30px;
  }

  @media screen and (max-width: 742px) {
    padding: 20px;
    height: 32rem;
  }
  
  @media screen and (max-width: 431px) {
    height: 40rem;
    padding: 20px 30px;
  }

  @media screen and (max-width: 332px) {
    height: 50rem;
    padding: 10px 30px;
  }
`;

export const ClientName = styled.h5`
  margin: 25px;
  color: #000;

  @media screen and (max-width: 431px) {
    margin: 10px;
  }
`;

export const ClientText = styled.p`
  font-style: italic;
  color: #222;
  max-width: 80%;
  margin: 0 200px;

  @media screen and (max-width: 1183px) {
    margin: 0 100px;
  }

  @media screen and (max-width: 825px) {
    margin: 0 50px;
  }

  @media screen and (max-width: 742px) {
    margin: 0 30px;
  }

  @media screen and (max-width: 431px) {
    margin: 0 10px;
    max-width: 100%;
  }
`;
