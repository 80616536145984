import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import checkboxImage from "../../images/checkbox.png";
import SideImage from "../../images/intern.png";
import IndImage from "../../images/ind.png";
import FinImage from "../../images/fin.png";
import UsImage from "../../images/us.png";
import DataImage from "../../images/data.png";
import {Section1, H1, Section2, H2, Choose, Choose1, Choose2, ChooseCard, ChooseWrap, P, Img, Img1, H5, H3, Li} from './SolutionsElements'

const Sol = () => {
  return (
    <>
    <Section1>
      <H1>Healthcare Management System </H1>
      <H3 style={{color: "#e07038", fontWeight: "bold"}}>A Complete Healthcare Management System for Hospitals, Clinics and Pharmacy.</H3>
      <ul style={{color: "#1e3045", padding: "8px 0"}}>
        <Li>Cutting edge solutions Tailored to 
meet the unique needs of our client </Li>
<Li>Agile approach – Customer is a part of
 SDLC </Li>
<Li>Cloud based solutions with future
 Readiness</Li>
      </ul>
    </Section1>
    <Section2>
      <Choose>
        <Choose1>
          <h1 style={{paddingBottom: "50px", fontSize: "2.2rem", fontWeight: "bold"}}>Our core modules</h1>
          <ul style={{ listStyle: "none", paddingLeft: "0", alignItems: "center", justifyContent: "center" , fontSize: "20px", color: "#000"}}>
            <li style={{ backgroundImage: `url(${checkboxImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "left 0 center", padding: "10px 30px" }}>Admission</li>
            <li style={{ backgroundImage: `url(${checkboxImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "left 0 center", padding: "10px 30px" }}>OPD Management</li>
            <li style={{ backgroundImage: `url(${checkboxImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "left 0 center", padding: "10px 30px" }}>Billing management across various departments</li>
            <li style={{ backgroundImage: `url(${checkboxImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "left 0 center", padding: "10px 30px" }}>Inventory management</li>
            <li style={{ backgroundImage: `url(${checkboxImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "left 0 center", padding: "10px 30px" }}>Lab/ Diagnostic</li>
          </ul>
        </Choose1>
        <Img src={SideImage} style={{width: "32vw", height: "auto"}}></Img>
      </Choose>
    </Section2>
    <Choose2>
          <h1 style={{textAlign: "center", paddingBottom: "50px", fontSize: "2.2rem", fontWeight: "bold"}}>Features</h1>
          <ChooseWrap>
          <ChooseCard>
          <Img1 src={IndImage} style={{width: "10vw", height: "auto"}}></Img1>
        <h3 style={{textAlign: "center"}}> Intuitive Interface</h3>
        <P>
          User-friendly and accessible, designed for seamless adoption by all staff members.
        </P></ChooseCard>
      <ChooseCard>
      <Img1 src={FinImage} style={{width: "10vw", height: "auto"}}></Img1>
        <h3 style={{textAlign: "center"}}>  Cloud Based Solution</h3>
        <P>
          Manage operations and patient interactions from anywhere, anytime, on any device.
        </P></ChooseCard>
      <ChooseCard>
      <Img1 src={UsImage} style={{width: "10vw", height: "auto"}}></Img1>
        <h3 style={{textAlign: "center"}}> Scalable Solution </h3>
        <P>
          Grows with your needs, supporting hospitals of all sizes and complexities.
        </P></ChooseCard>
      <ChooseCard>
      <Img1 src={DataImage} style={{width: "10vw", height: "auto"}}></Img1>
        <h3 style={{textAlign: "center"}}> Dedicated Support</h3>
        <P>
          Our team is here to guide you every step of the way, ensuring your success.
        </P></ChooseCard></ChooseWrap>
        </Choose2>
    </>
  );
};

export default Sol;
