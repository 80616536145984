import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import VMImage from "../../images/vm.png"
import WhyImage from "../../images/why.png"
import VisionImage from "../../images/vision.jpg"
import MissionImage from "../../images/mission.jpg"
import { InfoContainer, InfoWrapper, Title, VisionMission, VMWrapper, VMContent, VMText } from "./infoElements";
import { ButtonBasicInv } from "../ButtonElements";

export const InfoSectionLight = () => {
  return (
    <>
      <VisionMission className="visionmission">
        <VMWrapper className="vmwrapper">
          {/* <div style={{boxShadow: "25px 0 0 rgba(224, 112, 59, 1)", borderRadius: "50%"}} className="vmimgbg">
            <img src={VMImage} style={{height: "400px", width: "400px", borderRadius: "50%", border: "20px solid #fff", boxShadow: "0px 30px 0 rgba(0, 0, 0, 1)"}} className="vmimage"></img>
          </div> */}
          <VMContent>
            <VMText>
            <div style={{boxShadow: "10px 0 0 rgba(224, 112, 59, 1)", borderRadius: "50%"}} className="vmimgbg">
              <img src={VisionImage} style={{height: "150px", width: "150px", borderRadius: "50%", border: "10px solid #fff", boxShadow: "0px 10px 0 rgba(0, 0, 0, 1)"}} className="vmimage"></img>
            </div>
            <div className="vmcard">
              <h3 style={{color: "#000", fontWeight: "bold"}} className="vismis">Our Vision</h3>
              <p>
              Our vision at XpertLyte is to drive innovation and empower individuals, creating a future where
              lives are transformed through limitless possibilities.
              </p>
            </div>
            </VMText>
            <VMText>
            <div style={{boxShadow: "10px 0 0 rgba(224, 112, 59, 1)", borderRadius: "50%"}} className="vmimgbg">
              <img src={MissionImage} style={{height: "150px", width: "150px", borderRadius: "50%", border: "10px solid #fff", boxShadow: "0px 10px 0 rgba(0, 0, 0, 1)"}} className="vmimage"></img>
            </div>
            <div className="vmcard">
              <h3 style={{color: "#000", fontWeight: "bold"}} className="vismis">Our Mission</h3>
              <p>
              Our mission at XpertLyte is to harness the power of modern technology as a catalyst for
              adaptive change, empowering individuals to realize their full potential.
              </p>
            </div>
            </VMText>
          </VMContent>
        </VMWrapper>
      </VisionMission>
    </>
  );
};

export default InfoSectionLight;
